<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <BreadcrumbsComponent title="О проекте" />
        <section class="section open">
          <EditorJSComponent :text="JSON.parse(icAbout.description)" v-if="icAbout && icAbout.description" />
          <span v-else>Информация отсутствует</span>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "CityIdeasAbout",
  components: {
    EditorJSComponent,
    BreadcrumbsComponent,
  },
  async asyncData({ apollo, store }) {
    await store.dispatch("GET_IC_ABOUT", {
      apollo: apollo.clients.ic,
    });
  },
  computed: {
    icAbout() {
      return this.$store.state?.ic_about_page;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
